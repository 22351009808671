export const calcOpeningTimesDateForETAAlgo = (
  openingTimesArray?: any[]
): OpeningTimesDate[] => {
  if (!openingTimesArray || openingTimesArray.length === 0) {
    return [];
  }

  const todayDay = new Date().getDay();
  const tomorrowDay = todayDay === 6 ? 0 : todayDay + 1;

  const result: OpeningTimesDate[] = [];

  const toOpeningTimesDate = (dayOffset: number, time: string): string => {
    const baseDate = new Date(
      new Date().getTime() + 24 * dayOffset * 60 * 60 * 1000
    );
    setTimeOnDate(baseDate, time);
    return baseDate.toISOString();
  };

  for (const openingTimes of openingTimesArray) {
    if (openingTimes.weekdays.includes(days[todayDay])) {
      result.push({
        startDateTime: toOpeningTimesDate(0, openingTimes.startTime),
        endDateTime: toOpeningTimesDate(0, openingTimes.endTime),
      });
    }

    if (openingTimes.weekdays.includes(days[tomorrowDay])) {
      result.push({
        startDateTime: toOpeningTimesDate(1, openingTimes.startTime),
        endDateTime: toOpeningTimesDate(1, openingTimes.endTime),
      });
    }
  }

  return result;
};

function setTimeOnDate(date: Date, time: string): void {
  const timeSplit = time.split(":");
  date.setHours(parseInt(timeSplit[0]));
  date.setMinutes(parseInt(timeSplit[1]));
  date.setSeconds(parseInt(timeSplit[2]));
}

export const calcOpeningTimesOverview = (
  openingTimesArray?: any[]
): OpeningTimesOverviewLine[] | null => {
  if (!openingTimesArray) {
    return null;
  }

  const openingTimesArrayComparable = toOpeningTimesArrayComparable(
    openingTimesArray
  ).map((openingTimes) => {
    return openingTimesSortWeekdays(openingTimes);
  });

  // split weekdays with holes
  const openingTimesArrayComparableSplitted = [];
  for (const openingTimes of openingTimesArrayComparable) {
    if (openingTimes.weekdays.length === 1) {
      openingTimesArrayComparableSplitted.push(openingTimes);
      continue;
    }

    let lastWeekdayIndex = -1;
    let weekdayBuffer: number[] = [];

    for (let i = 0; i < openingTimes.weekdays.length; i++) {
      const weekdayIndex = openingTimes.weekdays[i];

      // do not split when first item
      const split =
        (lastWeekdayIndex !== 6
          ? weekdayIndex !== lastWeekdayIndex + 1
          : weekdayIndex !== 0) && i !== 0;

      if (split) {
        openingTimesArrayComparableSplitted.push({
          weekdays: weekdayBuffer,
          endTime: openingTimes.endTime,
          startTime: openingTimes.startTime,
        });
        weekdayBuffer = [];
      }

      weekdayBuffer.push(weekdayIndex);

      if (i === openingTimes.weekdays.length - 1) {
        openingTimesArrayComparableSplitted.push({
          weekdays: weekdayBuffer,
          endTime: openingTimes.endTime,
          startTime: openingTimes.startTime,
        });
      }

      lastWeekdayIndex = weekdayIndex;
    }
  }

  const openingTimesArraySortedStartTime = openingTimesArrayComparableSplitted.sort(
    compareOpeningTimes
  );

  const openingTimesArraySortedStartTimeWeekday = openingTimesArraySortedStartTime.sort(
    (a, b) => {
      const compareValA = a.weekdays[0] === 0 ? 7 : a.weekdays[0];
      const compareValB = b.weekdays[0] === 0 ? 7 : b.weekdays[0];
      return compareValA - compareValB;
    }
  );

  const openingTimesArrayRangeStringed: OpeningTimesOverviewLine[] = openingTimesArraySortedStartTimeWeekday.map(
    (openingTimes) => {
      const dayRange =
        openingTimes.weekdays.length === 1
          ? daysDisplay[openingTimes.weekdays[0]]
          : `${daysDisplay[openingTimes.weekdays[0]]}-${
              daysDisplay[
                openingTimes.weekdays[openingTimes.weekdays.length - 1]
              ]
            }`;
      return {
        dayRange,
        hourRanges: [
          `${intToOpeningTime(openingTimes.startTime).substr(
            0,
            5
          )}-${intToOpeningTime(openingTimes.endTime).substr(0, 5)}`,
        ],
      };
    }
  );

  // remove duplicate day entries
  const result: OpeningTimesOverviewLine[] = [];
  for (let i = 0; i < openingTimesArrayRangeStringed.length; i++) {
    const openingTimes = openingTimesArrayRangeStringed[i];

    let added = false;
    for (let j = 0; j < result.length; j++) {
      if (result[j].dayRange === openingTimes.dayRange) {
        result[j].hourRanges.push(openingTimes.hourRanges[0]);
        added = true;
      }
    }

    if (!added) {
      result.push(openingTimes);
    }
  }

  return result;
};

export const calcOpeningStatus = (openingTimesArray: any[]): OpeningStatus => {
  const openingTimesArrayComparable = toOpeningTimesArrayComparable(
    openingTimesArray
  ).map((openingTimes) => {
    return openingTimesSortWeekdays(openingTimes);
  });

  const openingTimesArrayOrderedStartTime = openingTimesArrayComparable.sort(
    compareOpeningTimes
  );

  const now = new Date();
  const currentDayIndex = now.getDay();
  const secondsPassedToday =
    now.getHours() * 60 * 60 + now.getMinutes() * 60 + now.getSeconds();

  const activeOpeningTime = openingTimesArrayOrderedStartTime.find(
    (openingTimes) => {
      if (!openingTimes.weekdays.includes(currentDayIndex)) return false;

      if (
        secondsPassedToday >= openingTimes.startTime &&
        secondsPassedToday <= openingTimes.endTime
      )
        return true;

      return false;
    }
  );

  if (activeOpeningTime) {
    return {
      isOpen: true,
      openingTimeHint: `bis ${intToOpeningTime(
        activeOpeningTime.endTime
      ).substr(0, 5)} h`,
      stateChangeDate: stateChangeFromInt(activeOpeningTime.endTime, 0),
    };
  } else {
    let toCheckDayIndex = currentDayIndex;
    let daysInFuture = 0;
    while (true) {
      const nextOpeningTime = openingTimesArrayOrderedStartTime.find(
        (openingTime) => {
          if (!openingTime.weekdays.includes(toCheckDayIndex)) return false;
          return true;
        }
      );

      if (
        !nextOpeningTime ||
        (daysInFuture === 0 && secondsPassedToday > nextOpeningTime.endTime)
      ) {
        // raise by 1 and continue loop
        daysInFuture = daysInFuture + 1;
        toCheckDayIndex = toCheckDayIndex + 1;
        if (toCheckDayIndex > 6) toCheckDayIndex = 0;
      } else {
        const dayString =
          daysInFuture === 0 ? "" : " " + daysDisplay[toCheckDayIndex];
        return {
          isOpen: false,
          openingTimeHint: `bis${dayString} ${intToOpeningTime(
            nextOpeningTime.startTime
          ).substr(0, 5)} h`,
          stateChangeDate: stateChangeFromInt(
            nextOpeningTime.startTime,
            daysInFuture
          ),
        };
      }
    }
  }
};

const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
const daysDisplay = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];

function compareOpeningTimes(
  a: OpeningTimesComparable,
  b: OpeningTimesComparable
): number {
  return a.startTime - b.startTime;
}

function openingTimesSortWeekdays(
  openingTimes: OpeningTimesComparable
): OpeningTimesComparable {
  const sortedWeekdays = openingTimes.weekdays.sort((a, b) => {
    const compareValA = a === 0 ? 7 : a;
    const compareValB = b === 0 ? 7 : b;
    return compareValA - compareValB;
  });
  return Object.assign(openingTimes, { weekdays: sortedWeekdays });
}

function toOpeningTimesArrayComparable(
  openingTimesArray: any[]
): OpeningTimesComparable[] {
  const result: OpeningTimesComparable[] = openingTimesArray.map(
    (openingTimes) => {
      return {
        weekdays: openingTimes.weekdays.map((weekday: string) =>
          days.indexOf(weekday)
        ),
        startTime: openingTimeToInt(openingTimes.startTime),
        endTime: openingTimeToInt(openingTimes.endTime),
      };
    }
  );
  return result;
}

function openingTimeToInt(time: string): number {
  const [hours, minutes, seconds] = time.split(":");
  return (
    parseInt(hours, 10) * 60 * 60 +
    parseInt(minutes, 10) * 60 +
    parseInt(seconds, 10)
  );
}

function intToOpeningTime(timeInt: number): string {
  const timeParts = intToTimeFragments(timeInt);
  return `${pad(Math.floor(timeParts.hours), 2)}:${pad(
    Math.floor(timeParts.minutes),
    2
  )}:${pad(Math.floor(timeParts.seconds), 2)}`;
}

function intToTimeFragments(timeInt: number): TimeParts {
  const hours = timeInt / (60 * 60);
  const minutes = (timeInt - hours * 60 * 60) / 60;
  const seconds = timeInt - hours * 60 * 60 - minutes * 60;

  return {
    hours,
    minutes,
    seconds,
  };
}

function pad(num: number, size: number): string {
  let s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
}

function stateChangeFromInt(timeInt: number, daysInFuture = 0): Date {
  console.log({ timeInt, daysInFuture });
  const resultDate = new Date();
  const timeParts = intToTimeFragments(timeInt);
  // this changes month automatically
  resultDate.setDate(resultDate.getDate() + daysInFuture);
  resultDate.setHours(timeParts.hours);
  resultDate.setMinutes(timeParts.minutes);
  resultDate.setSeconds(timeParts.seconds);
  return resultDate;
}

interface OpeningTimesOverviewLine {
  dayRange: string;
  hourRanges: string[];
}

interface OpeningTimesComparable {
  weekdays: number[];
  startTime: number;
  endTime: number;
}

interface OpeningStatus {
  isOpen: boolean;
  openingTimeHint: string;
  stateChangeDate: Date;
}

interface TimeParts {
  hours: number;
  minutes: number;
  seconds: number;
}

export interface OpeningTimesDate {
  startDateTime: string;
  endDateTime: string;
}
