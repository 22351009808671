import React, { useState } from "react";
import "./App.css";
import logo from "./assets/images/logo.svg";
import success from "./assets/images/success.svg";
import location from "./assets/images/location.svg";
import clock from "./assets/images/clock.svg";
import appleStore from "./assets/images/apple-store.svg";
import googlePlay from "./assets/images/google-play.png";
import photo from "./assets/images/photo.jpg";
import photoSupport from "./assets/images/photoSupport.jpg";
import arrow from "./assets/images/arrow.svg";
import Star from "./components/Star";
import Lottie from "react-lottie";
import Hands from "./components/Hands";
import HandLove from "./components/HandLove";
import Bubble from "./components/Bubble";
import * as loaderImp from "../src/assets/animations/loader.json";

// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// import CheckoutForm from "./components/CheckoutForm";

// const stripePromise = loadStripe("pk_test_JJ1eMdKN0Hp4UFJ6kWXWO4ix00jtXzq5XG");

const loader: any = loaderImp;

enum Step {
  INITIAL,
  SUPPORT,
  AMOUNT,
  PAYMENT,
  SUCCESS,
  PAYMENT_PROCESSING,
  COMMENT,
  ADS,
}

function App() {
  const [step, setStep] = useState(Step.INITIAL);
  const [rating, setRating] = useState(0);
  const [tipAmount, setTipAmount] = useState(-1);
  const [overlayActive, setOverlayActive] = useState(false);
  const [comment, setComment] = useState("");

  const ratingPressed = (ratingValue: number) => {
    if (ratingValue === 1) {
      setRating(ratingValue);
      setStep(Step.SUPPORT);
    } else {
      setRating(ratingValue);
      setStep(Step.AMOUNT);
    }
  };

  const backPress = () => {
    switch (step) {
      case Step.AMOUNT:
      case Step.SUPPORT:
        setStep(Step.INITIAL);
        setRating(0);
        setTipAmount(0);
        break;
      case Step.PAYMENT:
        setStep(Step.AMOUNT);
        break;
    }
  };

  let stepText = "How was your experience?";

  switch (step) {
    case Step.AMOUNT:
      stepText = "How much would you like to tip?";
      break;

    case Step.PAYMENT:
      stepText = "Select your payment method";
      break;

    case Step.COMMENT:
      stepText = "Have a good word for Julia?";
      break;

    case Step.SUPPORT:
      stepText = "Talk to:";
      break;
  }

  let starSize = "";

  switch (step) {
    case Step.INITIAL:
      starSize = "big";
      break;

    case Step.SUCCESS:
      starSize = "medium";
      break;

    case Step.COMMENT:
    case Step.ADS:
      starSize = "hide";
      break;

    default:
      starSize = "small";
      break;
  }

  let receiverBigPhotoClassAddition = "Receiver__photo__single--hidden";

  switch (step) {
    case Step.COMMENT:
      receiverBigPhotoClassAddition = "Receiver__photo__single--small";
      break;

    case Step.SUCCESS:
      receiverBigPhotoClassAddition = "Receiver__photo__single--big";
      break;
  }

  const hideStandardHeader =
    step === Step.SUCCESS || step === Step.COMMENT || step === Step.ADS;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="App">
      {/* <Elements stripe={stripePromise}></Elements> */}
      <div
        className="ApplePayOverlay"
        style={{ display: overlayActive ? "block" : "none" }}
        onClick={() => {
          setStep(Step.PAYMENT_PROCESSING);
          setOverlayActive(false);
          setTimeout(() => {
            setStep(Step.SUCCESS);
            setTimeout(() => {
              setStep(Step.COMMENT);
            }, 3000);
          }, 3000);
        }}
      ></div>

      {step === Step.PAYMENT_PROCESSING && (
        <div className="PaymentProcessingOverlay">
          <Lottie options={defaultOptions} height={200} width={200} />
          Processing your payment...
        </div>
      )}

      <header className="App__header">
        <img src={logo} />
      </header>
      <div className="Content">
        {!hideStandardHeader && (
          <>
            <div
              className={
                "Receiver__card card " +
                (step === Step.SUPPORT ? "card--disabled" : "")
              }
            >
              <img src={photo} className="Receiver__card__photo photo" />
              <div className="Receiver__card__content">
                <div className="card__content__name">Julia Watson</div>
                <div className="Receiver__card__content-role">
                  Housekeeping
                </div>
                <div className="Receiver__card__content-rating"></div>
              </div>
            </div>
            <div className="LocationWrap">
              <div className="LocationName">
                <img src={clock} className="IconMini" />
                Proper Hotel
              </div>
              <div className="LocationCity">
                <img src={location} className="IconMini" />
                San Francisco
              </div>
            </div>
          </>
        )}

        {(step === Step.SUCCESS || step === Step.COMMENT) && (
          <img
            src={photo}
            className={
              "Receiver__photo__single " +
              (step === Step.COMMENT ? "Receiver__photo__single--small" : "")
            }
          />
        )}

        <div className="Step">
          {[
            Step.INITIAL,
            Step.AMOUNT,
            Step.SUPPORT,
            Step.PAYMENT,
            Step.COMMENT,
          ].includes(step) && (
            <div
              className="Step__header"
              style={{
                marginTop: step === Step.COMMENT ? "20px" : "0px",
              }}
            >
              <img
                src={arrow}
                style={{
                  visibility:
                    step === Step.INITIAL || step === Step.COMMENT
                      ? "hidden"
                      : "initial",
                }}
                onClick={() => backPress()}
                className="Step__arrow"
              />
              <div className="Step__question">{stepText}</div>
            </div>
          )}

          <div className={"Rating__stars " + starSize}>
            <Star
              active={rating >= 1}
              onClick={() => (step === Step.INITIAL ? ratingPressed(1) : null)}
            />
            <Star
              active={rating >= 2}
              onClick={() => (step === Step.INITIAL ? ratingPressed(2) : null)}
            />
            <Star
              active={rating >= 3}
              onClick={() => (step === Step.INITIAL ? ratingPressed(3) : null)}
            />
            <Star
              active={rating >= 4}
              onClick={() => (step === Step.INITIAL ? ratingPressed(4) : null)}
            />
            <Star
              active={rating >= 5}
              onClick={() => (step === Step.INITIAL ? ratingPressed(5) : null)}
            />
            <Star
              active={rating === 5 && tipAmount >= 800}
              style={{
                display:
                  step !== Step.INITIAL && rating === 5 ? "initial" : "none",
                opacity: 1,
              }}
            />
          </div>

          {step === Step.COMMENT && (
            <div className="StepComment">
              <div
                className={
                  "StepComment__Button " +
                  (comment === "11" ? "StepComment__Button--active" : "")
                }
                onClick={() => setComment("11")}
              >
                <Bubble active={comment === "11"} />
                friendly
              </div>
              <div
                className={
                  "StepComment__Button " +
                  (comment === "22" ? "StepComment__Button--active" : "")
                }
                onClick={() => setComment("22")}
              >
                <HandLove active={comment === "22"} />
                helpful service
              </div>
              <div
                className={
                  "StepComment__Button " +
                  (comment === "33" ? "StepComment__Button--active" : "")
                }
                onClick={() => setComment("33")}
              >
                <Hands active={comment === "33"} />
                knowledgeable
              </div>
              <div className="StepComment__CustomButton">
                write your own feedback
              </div>
              <div
                className={"CTA " + (comment === "" ? "CTA--disabled" : null)}
                onClick={() => {
                  setStep(Step.ADS);
                }}
                style={{
                  marginTop: "25px",
                }}
              >
                done
              </div>
            </div>
          )}

          {step === Step.SUPPORT && (
            <div className="StepSupport">
              <div className="StepSupport__SupportMemberCard card">
                <div className="StepSupport__SupportMemberCard__content">
                  <div className="card__content__name">John Smith</div>
                  <div className="card__content__role">Customer Happiness</div>
                </div>
                <img src={photoSupport} className="photo" />
                <div className="online"></div>
              </div>
              <div className="StepSupport__Chat__ChatBubble">
                Sorry to hear something went wrong.
                <br />
                We'd like to make that up to you.
              </div>
              <div className="StepSupport__Chat__InputWrap">
                <input
                  type="text"
                  className="StepSupport__Chat__InputWrap__Input"
                  placeholder="your message"
                />
                <div className="StepSupport__Chat__InputWrap__SendButton">
                  send
                </div>
              </div>
            </div>
          )}

          {step === Step.AMOUNT && (
            <div
              className="TipAmount"
              style={{
                display: step === Step.AMOUNT ? "block" : "none",
              }}
            >
              <div className="TipAmount__standard">
                <div
                  onClick={() => setTipAmount(200)}
                  className={
                    "TipAmount__button " +
                    (tipAmount === 200 ? "TipAmount__button--active" : "")
                  }
                >
                  $2
                </div>
                <div
                  onClick={() => setTipAmount(400)}
                  className={
                    "TipAmount__button " +
                    (tipAmount === 400 ? "TipAmount__button--active" : "")
                  }
                >
                  $4
                </div>
                <div
                  onClick={() => setTipAmount(600)}
                  className={
                    "TipAmount__button " +
                    (tipAmount === 600 ? "TipAmount__button--active" : "")
                  }
                >
                  $6
                </div>
              </div>
              <div
                className="TipAmount__sixStar"
                style={{
                  display: rating !== 5 ? "none" : "flex",
                }}
              >
                <div className="TipAmount__note">
                  Make it a<br />6 star rating!
                </div>
                <div
                  onClick={() => setTipAmount(800)}
                  className={
                    "TipAmount__button " +
                    (tipAmount >= 800 ? "TipAmount__button--active" : "")
                  }
                >
                  $8 + <Star strokeWidth={tipAmount >= 800 ? "0" : "2"} />
                </div>
              </div>
              <div className="TipAmount__custom">
                <div className="TipAmount__custom__button">
                  enter custom amount
                </div>
                {/* <input
                className="TipAmount__custom__input"
                placeholder="enter custom amount"
              /> */}
              </div>
              <div
                className={"CTA " + (tipAmount > 0 ? null : "CTA--disabled")}
                onClick={() => {
                  if (tipAmount > 0) setStep(Step.PAYMENT);
                }}
                style={{
                  marginTop: "25px",
                }}
              >
                done
              </div>
              <div
                className="Tip__skip__button"
                onClick={() => setStep(Step.COMMENT)}
              >
                skip and continue without tip
              </div>
            </div>
          )}

          {step === Step.PAYMENT && (
            <div
              className="PaymentProvider"
              style={{
                display: step === Step.PAYMENT ? "block" : "none",
              }}
            >
              <div
                className="PaymentButton"
                onClick={() => {
                  setOverlayActive(true);
                }}
              >
                Credit Card
              </div>
              <div
                className="PaymentButton"
                onClick={() => {
                  setOverlayActive(true);
                }}
              >
                Apple Pay
              </div>
            </div>
          )}

          {step === Step.SUCCESS && (
            <div className="StepSuccess">
              <div className="headline">thank you</div>
              <div className="subHeadline">Julia has received your tip.</div>
              <img src={success} />
            </div>
          )}

          {step === Step.ADS && (
            <div className="StepAds">
              <div className="headline">GET THE APP</div>
              <div className="StepAds__marketingText">
                <p style={{ marginTop: 40 }}>
                  Give tips and ratings easily with uptip!
                </p>
                <ul>
                  <li>Free, Easy, Anonymous</li>
                  <li>Track your tipping expenses</li>
                  <li>Get better service</li>
                </ul>
              </div>
              <p style={{ marginTop: 50 }}>Download the uptip App</p>
              <img className="AppleStoreButton" src={appleStore} />
              <img className="GooglePlayButton" src={googlePlay} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
