import React from "react";

function Star(props: any) {
  return (
    <svg
      style={props.style}
      onClick={() => (props.onClick ? props.onClick() : null)}
      className="Star"
      xmlns="http://www.w3.org/2000/svg"
      width="47.354"
      height="43.929"
      viewBox="0 0 47.354 43.929"
    >
      <g id="star" transform="translate(2.344 2.374)">
        <path
          id="star_path"
          d="M29.333,963.362,35.6,976.846l15.07,1.648-11.2,9.983,3.051,14.5-13.185-7.315-13.185,7.315,3.051-14.5L8,978.495l15.07-1.648Z"
          transform="translate(-8 -963.362)"
          fill={props.active ? "#6DC9C8" : "#fff"}
          stroke={props.active ? "#6DC9C8" : "#b2b9c4"}
          strokeWidth={props.strokeWidth || "2"}
        />
      </g>
    </svg>
  );
}

export default Star;
