import React from "react";

function Bubble(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33.176"
      height="34"
      viewBox="0 0 33.176 34"
    >
      <g
        id="Gruppe_117"
        data-name="Gruppe 117"
        transform="translate(-3.7 -2.5)"
      >
        <path
          id="Pfad_28"
          data-name="Pfad 28"
          d="M30.463,2.5H10.113A6.43,6.43,0,0,0,3.7,8.913V22.957a6.43,6.43,0,0,0,6.413,6.413h.179l-.717,4.55a2.217,2.217,0,0,0,1.111,2.293,2.413,2.413,0,0,0,1.075.287,2.254,2.254,0,0,0,1.469-.537l7.6-6.556h9.638a6.43,6.43,0,0,0,6.413-6.413V8.913A6.407,6.407,0,0,0,30.463,2.5Zm3.475,20.493a3.478,3.478,0,0,1-3.475,3.475H20.288a1.529,1.529,0,0,0-.967.358L12.8,32.416l.681-4.263a1.539,1.539,0,0,0-.322-1.182,1.469,1.469,0,0,0-1.111-.5h-1.9a3.478,3.478,0,0,1-3.475-3.475V8.913a3.478,3.478,0,0,1,3.475-3.475H30.5a3.478,3.478,0,0,1,3.475,3.475v14.08Z"
          fill={props.active ? "#fff" : "#adb3ba"}
        />
        <path
          id="Pfad_29"
          data-name="Pfad 29"
          d="M44.764,42.306a1.439,1.439,0,0,0-1.756,1,2.537,2.537,0,0,1-2.472,1.863,2.579,2.579,0,0,1-2.472-1.863,1.439,1.439,0,0,0-1.756-1,1.423,1.423,0,0,0-1,1.756,5.428,5.428,0,0,0,10.462,0A1.439,1.439,0,0,0,44.764,42.306Z"
          transform="translate(-20.249 -25.511)"
          fill={props.active ? "#fff" : "#adb3ba"}
        />
        <circle
          id="Ellipse_6"
          data-name="Ellipse 6"
          cx="2.15"
          cy="2.15"
          r="2.15"
          transform="translate(14.018 9.415)"
          fill={props.active ? "#fff" : "#adb3ba"}
        />
        <circle
          id="Ellipse_7"
          data-name="Ellipse 7"
          cx="2.15"
          cy="2.15"
          r="2.15"
          transform="translate(22.258 9.415)"
          fill={props.active ? "#fff" : "#adb3ba"}
        />
      </g>
    </svg>
  );
}

export default Bubble;
