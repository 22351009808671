import React from "react";

function HandLove(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.965"
      height="33"
      viewBox="0 0 32.965 33"
    >
      <g
        id="noun_sharing_love_2999243"
        data-name="noun_sharing love_2999243"
        transform="translate(-2 -2)"
      >
        <g
          id="sharing_love"
          data-name="sharing love"
          transform="translate(2 2)"
        >
          <path
            id="Pfad_30"
            data-name="Pfad 30"
            d="M2,35V20.692L7.011,18.55a11.877,11.877,0,0,1,10.023.288l4.084,2.055a2.994,2.994,0,0,1,.99.764l8.043-5.362a3.12,3.12,0,0,1,3.909.388,3.157,3.157,0,0,1,.9,2.343,3.107,3.107,0,0,1-1.128,2.243L22.672,30.4a4.147,4.147,0,0,1-3.42.852l-4.723-.94A9.233,9.233,0,0,0,8,31.454ZM4.506,22.346V30.59l2.155-1.253A11.752,11.752,0,0,1,14.967,27.9l4.736.94a1.7,1.7,0,0,0,1.353-.338l11.15-9.133a.551.551,0,0,0,.213-.426.564.564,0,0,0-.163-.438.6.6,0,0,0-.752-.075L22.835,24.2a3.358,3.358,0,0,1-.138.614,3.22,3.22,0,0,1-4.485,1.867l-5.5-2.756,1.128-2.243,5.487,2.756a.689.689,0,0,0,.589,0,.677.677,0,0,0,.4-.426.7.7,0,0,0-.351-.852l-4.1-2.055a9.371,9.371,0,0,0-7.88-.226ZM19.54,18.062l-6.59-5.65a8.106,8.106,0,0,1-2.506-3.884,4.585,4.585,0,0,1-.2-1.253A5.312,5.312,0,0,1,15.493,2,5.237,5.237,0,0,1,19.54,3.892,5.237,5.237,0,0,1,23.587,2a5.312,5.312,0,0,1,5.3,5.3,4.51,4.51,0,0,1-.2,1.253h0a8.106,8.106,0,0,1-2.506,3.871ZM15.493,4.506A2.794,2.794,0,0,0,12.7,7.3a1.954,1.954,0,0,0,.113.526,5.55,5.55,0,0,0,1.716,2.681l5.011,4.247,5.011-4.26a5.425,5.425,0,0,0,1.754-2.656h0a1.979,1.979,0,0,0,.113-.539,2.794,2.794,0,0,0-5.187-1.541,2.017,2.017,0,0,1-3.383,0,2.806,2.806,0,0,0-2.355-1.253Z"
            transform="translate(-2 -2)"
            fill={props.active ? "#fff" : "#adb3ba"}
          />
        </g>
      </g>
    </svg>
  );
}

export default HandLove;
